<template>
  <div class="container dark-theme">
    <h3>Código de autenticação</h3>
    <p>Utilize o código abaixo para validar o login.</p>
    <div class="code-container mb-3">
      <div class="code-box">
        <h3 class="code">{{ code.code }}</h3>
        <p
          v-if="timer > 0"
          class="timer-text"
        >
          Código expira em {{ timer }}s
        </p>
        <p
          v-else
          class="text-danger"
        >
          Gerando novo código
        </p>
      </div>
    </div>

    <!-- Barra de progresso -->
    <div class="progress-container">
      <div
        class="progress-bar"
        :style="{ width: progressBarWidth }"
      ></div>
    </div>
  </div>
</template>

<script>
  import { getCode } from "@/api";

  export default {
    name: "HomeView",
    data() {
      return {
        code: { code: "Loading..." },
        timer: 20,
        intervalId: null,
        progressBarWidth: "0%",
      };
    },
    methods: {
      generateCode() {
        const email = localStorage.getItem("authEmail");

        if (!email) {
          this.$router.push({ name: "login" });
          return;
        }

        // Chama a API para gerar o código
        getCode(email).then((newCode) => {
          this.code = newCode;
          this.startTimer(); // Inicia o timer após gerar o código
        });
      },
      startTimer() {
        // Inicia o contador
        if (this.intervalId) {
          clearInterval(this.intervalId); // Limpa qualquer intervalo anterior
        }

        this.timer = 20; // Reseta o timer para 20 segundos
        this.progressBarWidth = "100%"; // Inicia a barra de progresso

        // Atualiza o timer a cada segundo
        this.intervalId = setInterval(() => {
          if (this.timer > 0) {
            this.timer--;
            // Atualiza a largura da barra de progresso
            this.progressBarWidth = `${(this.timer / 20) * 100}%`;
          } else {
            // Quando o timer chega a zero, solicita um novo código
            this.generateCode();
          }
        }, 1000);
      },
    },
    mounted() {
      this.generateCode(); // Gera o código assim que a página carregar
    },
  };
</script>

<style scoped>
  /* Estilo do tema escuro */
  .dark-theme {
    background-color: #333;
    color: white;
  }

  /* Estilo da caixa que exibe o código */
  .code-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .code-box {
    text-align: center;
    padding: 20px;
    border: 2px solid #007bff;
    border-radius: 10px;
    background-color: #444;
  }

  .code {
    font-size: 2rem;
    font-weight: bold;
    color: #007bff;
  }

  .timer-text {
    margin-top: 10px;
    font-size: 1rem;
    color: #bbb;
  }

  .text-danger {
    font-size: 1rem;
    color: red;
  }

  /* Estilo da barra de progresso */
  .progress-container {
    width: 100%;
    height: 10px;
    background-color: #555;
    border-radius: 5px;
    margin-top: 10px;
  }

  .progress-bar {
    height: 100%;
    background-color: #28a745;
    border-radius: 5px;
    transition: width 1s linear;
  }
</style>
