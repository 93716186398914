<template>
  <div
    v-if="isVisible"
    class="modal-overlay"
  >
    <div class="modal-content">
      <h3>Aconteceu um problema!</h3>
      <p>{{ errorMessage }}</p>
      <div class="modal-actions">
        <button
          @click="closeModal"
          class="btn btn-secondary"
        >
          Fechar
        </button>
        <a
          :href="whatsappLink"
          target="_blank"
          class="btn btn-primary"
        >
          Entre em contato com o Suporte
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ErrorModal",
    props: {
      errorMessage: {
        type: String,
        required: true,
      },
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      whatsappLink() {
        return `https://wa.me/1234567890?text=${encodeURIComponent(
          this.errorMessage
        )}`;
      },
    },
    methods: {
      closeModal() {
        this.$emit("update:isVisible", false);
      },
    },
  };
</script>

<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    width: 100%;
    color: black !important;
  }

  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .modal-actions .btn {
    padding: 10px 20px;
  }

  .btn-primary {
    background-color: #007bff;
    color: white;
  }

  .btn-secondary {
    background-color: #6c757d;
    color: white;
  }
</style>
