import axios from "axios";

const API_URL = "https://iamind-validcode.fnc23x.easypanel.host";

export const registerClient = async (email, name, password) => {
  try {
    const response = await axios.post(API_URL + "/register-client", {
      email,
      name,
      password,
    });

    return response.data;
  } catch (error) {
    console.log(error.response.data);
    return error.response.data;
  }
}

export const loginClient = async (email, password) => {
  try {
    const response = await axios.post(API_URL + "/auth-client", {
      email,
      password,
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const getCode = async (email) => {
  try {
    const response = await axios.post(API_URL + "/generate-code", {
      email,
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
