<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <h3>Entrar na conta:</h3>
        <form @submit.prevent="login">
          <div class="mb-3">
            <label
              for="email"
              class="form-label"
              >E-mail</label
            >
            <input
              id="email"
              v-model="email"
              type="email"
              class="form-control"
              required
            />
          </div>
          <div class="mb-3">
            <label
              for="password"
              class="form-label"
              >Senha</label
            >
            <input
              id="password"
              v-model="password"
              type="password"
              class="form-control"
              required
            />
          </div>
          <button
            type="submit"
            class="btn btn-primary w-100"
          >
            ENTRAR
          </button>
        </form>

        <!-- Botão para criar conta -->
        <div class="mt-3">
          <button
            class="btn btn-outline-light w-100"
            @click="goToRegister"
          >
            CRIAR CONTA
          </button>
        </div>
      </div>
    </div>

    <!-- Error Modal -->
    <ErrorModal
      :errorMessage="errorMessage"
      :isVisible="showErrorModal"
      @update:isVisible="showErrorModal = $event"
    />
  </div>
</template>

<script>
  import { loginClient } from "@/api";
  import ErrorModal from "@/components/ErrorModal.vue";

  export default {
    name: "LoginView",
    components: {
      ErrorModal,
    },
    data() {
      return {
        email: "",
        password: "",
        showErrorModal: false,
        errorMessage: "",
      };
    },
    methods: {
      login() {
        loginClient(this.email, this.password)
          .then((data) => {

            if (!data.isValid) {
              this.errorMessage = "Login ou senha inválidos.";
              this.showErrorModal = true;
              return;
            }

            localStorage.setItem("authEmail", this.email);
            this.$router.push({ name: "home" });
          })
          .catch((error) => {
            if (error.response && error.response.status !== 200) {
              this.errorMessage = "Login ou senha inválidos.";
              this.showErrorModal = true;
            } else {
              console.error("Unknown error", error);
              this.errorMessage = "Ocorreu um erro. Contate o suporte.";
              this.showErrorModal = true;
            }
          });
      },
      goToRegister() {
        this.$router.push({ name: "register" });
      },
    },
  };
</script>
