<template>
  <div class="container">
    <div class="row justify-content-center">
      <img src="./assets/logo.webp" alt="IAMind" style="height: 89px; width: auto;margin-bottom: 70px;" />
    </div>
  </div>
  <div class="container">
    <router-view/>
  </div>
  
</template>

<style>
html, body, #app {
  background-color: #333;
  color: white;
  font-family: Arial, sans-serif;
}

#app {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-top: 100px;
}

a {
  color: #007bff;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Barra de progresso com tema escuro */
.progress-container {
  background-color: #444;
}

.progress-bar {
  background-color: #28a745;
}
</style>
