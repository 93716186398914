<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <h3>Validação de conta</h3>
        <p>Crie sua senha com o e-mail utilizado no momento da compra.</p>
        <form @submit.prevent="register">
          <div class="mb-3">
            <label
              for="email"
              class="form-label"
              >E-mail</label
            >
            <input
              id="email"
              v-model="email"
              type="email"
              class="form-control"
              required
            />
          </div>
          <div class="mb-3">
            <label
              for="name"
              class="form-label"
              >Nome</label
            >
            <input
              id="name"
              v-model="name"
              type="text"
              class="form-control"
              required
            />
          </div>
          <div class="mb-3">
            <label
              for="password"
              class="form-label"
              >Senha</label
            >
            <input
              id="password"
              v-model="password"
              type="password"
              class="form-control"
              required
            />
          </div>
          <button
            type="submit"
            class="btn btn-primary"
          >
            CRIAR CONTA
          </button>
        </form>
      </div>
    </div>
  </div>

  <!-- Error Modal -->
  <ErrorModal
    :errorMessage="errorMessage"
    :isVisible="showErrorModal"
    @update:isVisible="showErrorModal = $event"
  />
</template>

<script>
  import { registerClient } from "@/api";
  import ErrorModal from "@/components/ErrorModal.vue";

  export default {
    name: "RegisterView",
    components: {
      ErrorModal,
    },
    data() {
      return {
        email: "",
        name: "",
        password: "",
        showErrorModal: false,
        errorMessage: "",
      };
    },
    methods: {
      register() {
        registerClient(this.email, this.name, this.password)
          .then((data) => {

            if (data.status !== 200) {
              this.errorMessage =
                "Ocorreu um erro ao registrar. Por favor, tente novamente.";
              this.showErrorModal = true;
              return;
            }

            localStorage.setItem("authEmail", this.email);
            this.$router.push({ name: "home" });
          })
          .catch((error) => {
            if (error.response && error.response.status !== 200) {
              this.errorMessage =
                "Ocorreu um erro ao registrar. Por favor, tente novamente.";
              this.showErrorModal = true;
            } else {
              console.error("Unknown error", error);
              this.errorMessage =
                "Ocorreu um erro. Por favor, tente novamente.";
              this.showErrorModal = true;
            }
          });
      },
    },
  };
</script>
